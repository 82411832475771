'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:fileGet
 * @description
 * # fileGet
 * Directive of the informaApp
 */
angular.module('informaApp')
	.directive('fileGet', [function () {
		return {
			restrict: 'E',
			templateUrl: 'directives/file-get/template.ptl.html',

			scope: {
				options: '=',
				file: '=?',
				error: '=?'
			},

			link: function(scope, element, attrs) {
				scope.$watch('file', function(e) {
					if(e !== null && scope.options.format) {
						scope.error = e && e.name.match(/\.\w+$/i)[0] !== scope.options.format;
					}
				});
			}
		};
	}]);
